import { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { toast } from 'react-toastify'

import { EventCardProps } from './eventCard.types'
import './eventCard.scss'
import { useFetch } from '../../utils/hooks'
import { TicketType } from '../../types'
import { TicketRow } from '../TicketRow'

export const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const { data: ticketsData, error: ticketsError } = useFetch<{
    data: TicketType[]
  }>(`/tickets/?event_id=${event.id}`)

  const [validTickets, setValidTickets] = useState<TicketType[]>([])

  useEffect(() => {
    if (ticketsData) {
      const today = new Date().getTime()

      const newTickets = ticketsData.data.filter((ticket) => {
        const startDate = new Date(ticket.sale_start_at || '').getTime()
        const endDate = new Date(ticket.sale_end_at || '').getTime()

        if (startDate < today && today < endDate) {
          return true
        }

        return false
      })

      setValidTickets([...newTickets])
    }
  }, [ticketsData])

  useEffect(() => {
    if (ticketsError) {
      toast.error(
        'Error loading the available tickets. Please try again in a bit.'
      )
    }
  }, [ticketsError])

  return (
    <Card className="rounded-4 border-primary overflow-hidden my-4">
      <div className="title-wrapper bg-primary text-white fw-bold fs-4 fs-md-6 p-3 d-flex flex-row">
        <p>{event.name}</p>
      </div>
      <div className="text-primary">
        {validTickets.map((ticket) => (
          <TicketRow
            key={ticket.id}
            ticket={ticket}
            event={event}
            tickets={event.seating_chart ? validTickets : []}
          />
        ))}
      </div>
    </Card>
  )
}
