import { useEffect, useState } from 'react'
import { PrintifyProductImageType } from '../../../types/printifyProduct.types'

import './productImageGallery.scss'

type ProductImageGalleryProps = {
  images: PrintifyProductImageType[]
}

export const ProductImageGallery = ({ images }: ProductImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState<
    PrintifyProductImageType | undefined
  >()

  useEffect(() => {
    if (images.length) {
      setSelectedImage(images[0])
    }
  }, [images.length])

  return (
    <div className="d-flex flex-column-reverse flex-md-row border-primary rounded-3 p-3 p-md-0 gallery-wrapper">
      <div className="d-flex flex-row flex-md-column images-wrapper me-3">
        {images.map((image) => (
          <div
            key={image.id}
            className={`me-2 me-md-0 mb-md-2 p-1 ${
              selectedImage?.id === image.id ? 'border-primary' : ''
            }`}
            onClick={() => setSelectedImage(image)}
            onKeyUp={() => setSelectedImage(image)}
            role="button"
            tabIndex={0}
          >
            <img className="w-100" src={image.url} alt={image.name} />
          </div>
        ))}
      </div>
      <div className="main-image p-1 mb-2 mb-md-0">
        {selectedImage ? (
          <img
            className="w-100"
            src={selectedImage.url}
            alt={selectedImage.name}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
