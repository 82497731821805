import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { PageTitle, PrintifyProductCard } from '../../../components'
import { useFetch } from '../../../utils/hooks'
import { PrintifyProductListType } from '../../../types/printifyProduct.types'

export const PrintifyProductList = () => {
  const { data: products, error } = useFetch<{
    data: PrintifyProductListType[]
  }>('/products?product_type=printify')

  useEffect(() => {
    if (error) {
      toast.error('Error fetching products. Please try again later.')
    }
  }, [error])

  return (
    <>
      <PageTitle title="Product Page" />
      <Container>
        <Row className="my-3 pt-4">
          {products?.data.map((product) => (
            <Col xs={6} sm={6} md={6} lg={4} key={product.id}>
              <PrintifyProductCard product={product} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}
