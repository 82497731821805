import { useEffect, useState, forwardRef, ReactNode, ForwardedRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { useModal } from '@ebay/nice-modal-react'
import { useNavigate, useLocation } from 'react-router-dom'

import { ReactComponent as SVGPerson } from '../../assets/icons/person.svg'
import { ReactComponent as SVGCart } from '../../assets/icons/cart.svg'

import './header.scss'
import { useAuthentication, useDeviceType } from '../../utils/contexts'
import { AuthModal } from '../AuthModal'
import { Menu } from './components/menu'
import { Cart } from './components/cart'

const CustomDropdownToggle = forwardRef(
  (
    { children, onClick }: { children: ReactNode; onClick: () => void },
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <Button
      ref={ref}
      variant="ghost"
      className="login d-flex align-items-center justify-content-center rounded-circle bg-primary p-1 p-md-3"
      onClick={onClick}
    >
      {children}
    </Button>
  )
)

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const { token, logout } = useAuthentication()
  const authModal = useModal(AuthModal)
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useDeviceType()

  useEffect(() => {
    setShowCart(false)
    setShowMenu(false)
  }, [location])

  const handleLogin = async () => {
    if (isMobile) {
      setShowMenu(false)
    }
    if (token) {
      navigate('/account')
    } else {
      await authModal.show()
    }
  }

  const handleLogout = () => {
    void logout()
    navigate('/tickets')
  }

  return (
    <>
      <Navbar>
        <Container fluid="md" className="navbar-container">
          <Row className="w-100 align-items-center">
            <Col xs={4} sm={4} md={2}>
              <Dropdown
                id="dropdown-item-button"
                className="d-flex justify-content-start"
              >
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="dropdown-custom-components"
                  className="d-flex justify-content-start"
                >
                  <SVGPerson />
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() => void handleLogin()}
                    disabled={token.length === 0}
                  >
                    Account
                  </Dropdown.Item>
                  {token.length === 0 ? (
                    <Dropdown.Item
                      as="button"
                      onClick={() => void handleLogin()}
                    >
                      Login
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item as="button" onClick={() => handleLogout()}>
                      Logout
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={4} sm={4} md={8}>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  href="/tickets"
                  variant="outline-light"
                  className="header-logo-wrapper border-0 p-3"
                >
                  <img
                    className="logo"
                    src="/assets/logo.png"
                    alt="Red Lotus Events"
                  />
                </Button>
              </div>
            </Col>
            <Col xs={4} sm={4} md={2} className="justify-content-end d-flex">
              <Button
                variant="ghost"
                className="cart d-flex align-items-center justify-content-center rounded-circle bg-primary p-2 p-md-3"
                onClick={() => setShowCart(true)}
              >
                <SVGCart />
              </Button>
            </Col>
          </Row>
        </Container>
      </Navbar>

      {/* <Menu showMenu={showMenu} setShowMenu={setShowMenu} /> */}
      <Cart showCart={showCart} setShowCart={setShowCart} />
    </>
  )
}
