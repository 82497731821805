import Container from 'react-bootstrap/Container'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'

import { ProductType } from '../../../types/product.types'
import { TabItems, ProductListType } from './exhibitorShop.types'
import { ExhibitorShopDesktop } from './exhibitorShopDesktop'
import { ExhibitorShopMobile } from './exhibitorShopMobile'
import './exhibitorShop.scss'

const InitialProductList: ProductListType = {
  [TabItems.EXHIBITOR_STALLS]: [],
  [TabItems.CATERING_STALLS]: [],
  [TabItems.INTERNATIONAL_STALLS]: [],
  [TabItems.ADD_ON]: [],
}

export const ExhibitorShop = () => {
  const [products, setProducts] = useState<ProductListType>(InitialProductList)

  const fetchProducts = async () => {
    try {
      const { data: response } = await axios.get<{ data: ProductType[] }>(
        '/products'
      )
      if (response.data) {
        const localProductList: ProductListType = { ...InitialProductList }

        response.data.forEach((element: ProductType) => {
          const category: string = element.category.name
            .toLowerCase()
            .replace(' ', '-')

          if (
            !localProductList[category as TabItems]?.some(
              (product) => product.id === element.id
            )
          ) {
            localProductList[category as TabItems]?.push({
              ...element,
              disabled: category === TabItems.CATERING_STALLS,
            })
          }

          localProductList[category as TabItems]?.sort((productA, productB) => {
            return productA.id - productB.id
          })
        })

        setProducts(() => ({ ...localProductList }))
      }
    } catch (error) {
      toast.error('Error fetching list of products. Please try again later')
    }
  }

  useEffect(() => {
    void fetchProducts()
  }, [])

  return (
    <Container>
      <ExhibitorShopMobile products={products} />
      <ExhibitorShopDesktop products={products} />
    </Container>
  )
}
