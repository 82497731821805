import { useContext } from 'react'
import { SeatsContextType } from './seatsContext.type'
import { SeatsContext } from './seatsContext'

export const useSeats = (): SeatsContextType => {
  const seatsContent = useContext(SeatsContext)

  if (!seatsContent) {
    throw new Error('useDeviceType was used outside of its provider')
  }

  return seatsContent
}
