// export const RootUrl = 'http://localhost:8000/api'
// export const RootUrl = 'https://admin.redlotusevents.com/api'
export const RootUrl = 'https://admin.tickets.marathondesignstudios.art/api'

export const AUTH_MODAL_ID = 'auth-modal'

export const CART_ITEM_TYPE_TICKET = 'App\\Models\\Ticket'
export const CART_ITEM_TYPE_PRODUCT = 'App\\Models\\Product'

export enum TicketCategories {
  GROUP = 'Group',
  GENERAL_ADMISSION = 'General Admission',
  VIP = 'VIP',
  EARLY_BIRD = 'Early Bird',
}

export const KIDS_EVENT_IDS = [14, 15, 17]
export const NORMAL_EVENT_IDS = [13, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]

export const KIDS_TICKET_IDS: { [key: number]: Array<number> } = {
  14: [47, 55], // Kids between 2 yrs and 10 yrs.
  15: [50, 56], // Kids between 2 yrs and 10 yrs.
  17: [44, 57], // Kids between 2 yrs and 10 yrs.
}

export const KIDS_BELOW_TWO_YRS_TICKET_IDS: { [key: number]: Array<number> } = {
  // 14: [55], // Kids Garba.
  // 15: [56], // Kids Garba.
  // 17: [57], // Kids Garba.
  13: [78], // Season pass.
  18: [58], //
  19: [60], //
  20: [62], //
  21: [64], //
  22: [77], //
  23: [72], //
  24: [73], //
  25: [74], //
  26: [75], //
  27: [76], //
}

export const ACCOMPANYING_ADULT_TICKET_IDS: { [key: number]: Array<number> } = {
  14: [48], // These are ticket ids for Accompanying parents in for Kids Garba.
  15: [51], // These are ticket ids for Accompanying parents in for Kids Garba.
  17: [45], // These are ticket ids for Accompanying parents in for Kids Garba.
  13: [53], // These are Single Adult tickets. Season Pass.
  18: [54], // These are Single Adult tickets
  19: [59], // These are Single Adult tickets
  20: [61], // These are Single Adult tickets
  21: [63], // These are Single Adult tickets
  22: [66], // These are Single Adult tickets
  23: [67], // These are Single Adult tickets
  24: [68], // These are Single Adult tickets
  25: [69], // These are Single Adult tickets
  26: [70], // These are Single Adult tickets
  27: [71], // These are Single Adult tickets
}
