import { ReactComponent as SVGWashmachine } from '../../../assets/icons/products/care/washmachine.svg'
import { ReactComponent as SVGIron } from '../../../assets/icons/products/care/iron.svg'
import { ReactComponent as SVGRoundCross } from '../../../assets/icons/products/care/round-cross.svg'
import { ReactComponent as SVGBucketWithStand } from '../../../assets/icons/products/care/bucket-stand.svg'
import { ReactComponent as SVGTriangleFilled } from '../../../assets/icons/products/care/triangle-filled.svg'
import { ReactComponent as SVGBucket } from '../../../assets/icons/products/care/bucket.svg'
import { ReactComponent as SVGTriangle } from '../../../assets/icons/products/care/triangle.svg'
import { ReactComponent as SVGPlug } from '../../../assets/icons/products/care/plug.svg'
import { ReactComponent as SVGIronCrossed } from '../../../assets/icons/products/care/iron-crossed.svg'
import { ReactComponent as SVGSquareDash } from '../../../assets/icons/products/care/square-dash.svg'
import { ReactComponent as SVGHandWash } from '../../../assets/icons/products/care/hand-wash.svg'
import { ReactComponent as SVGWashmachineCrossed } from '../../../assets/icons/products/care/washmachin-crossed.svg'
import { ReactComponent as SVGTriangleOutline } from '../../../assets/icons/products/care/triangle-outline.svg'

import { ReactComponent as SVGFiberComposition } from '../../../assets/icons/products/features/fiber-composition.svg'
import { ReactComponent as SVGWithSideSeams } from '../../../assets/icons/products/features/with-side-seams.svg'
import { ReactComponent as SVGSpaciousPocket } from '../../../assets/icons/products/features/spacious-pocket.svg'
import { ReactComponent as SVGFabric } from '../../../assets/icons/products/features/fabric.svg'
import { ReactComponent as SVGWithoutSideSeams } from '../../../assets/icons/products/features/without-side-seams.svg'
import { ReactComponent as SVGRibbedKnitCollarWithoutSeam } from '../../../assets/icons/products/features/ribbed-knit-collar-without-seam.svg'
import { ReactComponent as SVGShoulderTape } from '../../../assets/icons/products/features/shoulder-tape.svg'
import { ReactComponent as SVGDoubleRibbedBinding } from '../../../assets/icons/products/features/double-ribbed-binding.svg'
import { ReactComponent as SVGLapShoulder } from '../../../assets/icons/products/features/lap-shoulder.svg'
import { ReactComponent as SVGRibbedKnit } from '../../../assets/icons/products/features/ribbed-knit.svg'
import { ReactComponent as SVGThreeSnapClosure } from '../../../assets/icons/products/features/three-snap-closure.svg'
import { ReactComponent as SVGCrewLength } from '../../../assets/icons/products/features/crew-length.svg'
import { ReactComponent as SVGVibrantColor } from '../../../assets/icons/products/features/all-over-print.svg'
import { ReactComponent as SVGGlossyFinish } from '../../../assets/icons/products/features/glossy-finish.svg'
import { ReactComponent as SVGCorkBottom } from '../../../assets/icons/products/features/cork-bottom.svg'
import { ReactComponent as SVGTemperedGlass } from '../../../assets/icons/products/features/tempered-glass.svg'
import { ReactComponent as SVGUniqueDecoration } from '../../../assets/icons/products/features/unique-decoration.svg'
import { ReactComponent as SVGRubberDots } from '../../../assets/icons/products/features/rubber-dots.svg'
import { ReactComponent as SVGOverloackSeams } from '../../../assets/icons/products/features/overlock-seams.svg'
import { ReactComponent as SVGLightweight } from '../../../assets/icons/products/features/lightweight.svg'
import { ReactComponent as SVGTieBackClosure } from '../../../assets/icons/products/features/tie-back-closure.svg'
import { ReactComponent as SVGMadeForKids } from '../../../assets/icons/products/features/made-for-kids.svg'
import { ReactComponent as SVGBoxWithPuzzle } from '../../../assets/icons/products/features/box-with-puzzle.svg'
import { ReactComponent as SVGShuffling } from '../../../assets/icons/products/features/shuffling.svg'
import { ReactComponent as SVGDiamond } from '../../../assets/icons/products/features/diamond.svg'
import { ReactComponent as SVGSustainable } from '../../../assets/icons/products/features/sustainable.svg'
import { ReactComponent as SVGStainlessSteel } from '../../../assets/icons/products/features/stainless-steel.svg'
import { ReactComponent as SVGLeakProof } from '../../../assets/icons/products/features/leak-proof.svg'
import { ReactComponent as SVGZip } from '../../../assets/icons/products/features/zip.svg'
import { ReactComponent as SVGDishwasher } from '../../../assets/icons/products/features/dishwasher.svg'
import { ReactComponent as SVGDrawStringHood } from '../../../assets/icons/products/features/drawstring-hood.svg'

type ExtraProductInfoType = {
  [key: string]: {
    name: string
    features: {
      icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string | undefined }
      >
      title: string
      description: string
    }[]
    care: {
      icons: Array<
        React.FunctionComponent<
          React.SVGProps<SVGSVGElement> & { title?: string | undefined }
        >
      >
      text: string
    }
  }
}

export const ExtraProductInfo: ExtraProductInfoType = {
  '645ab1732d389739830feee6': {
    name: 'Kids Hoodie',
    features: [
      {
        icon: SVGFiberComposition,
        title: '80% Cotton, 20% Polyester',
        description:
          'The fabric is soft and pleasant to touch, has a subtle luxurious feel. Made from specially spun fibers that make very strong and smooth fabric, perfect for printing. NB! Heather Grey is 75% Cotton, 25% Polyester',
      },
      {
        icon: SVGWithSideSeams,
        title: 'With side seams',
        description:
          "Located along the sides, they help hold the garment's shape longer and give it structural support",
      },
      {
        icon: SVGSpaciousPocket,
        title: 'Spacious pocket',
        description: 'Kangaroo pouch pocket will always keep your hands warm',
      },
    ],
    care: {
      icons: [
        SVGWashmachine,
        SVGIron,
        SVGRoundCross,
        SVGBucketWithStand,
        SVGTriangleFilled,
      ],
      text: 'Tumble dry: low heat; Iron, steam or dry: low heat; Do not dry clean; Machine wash: cold (max 30C or 90F), permanent press; Do not bleach.',
    },
  },
  '645ab0d56dc19ab9750b411a': {
    name: 'Unisex Heavy Cotton Tee',
    features: [
      {
        icon: SVGFiberComposition,
        title: 'Fiber composition',
        description:
          'Solid colors are 100% cotton; Heather colors are 50% cotton, 50% polyester (Sport Grey and Antique colors are 90% cotton, 10% polyester. Ash is 99% cotton, 1% polyester)',
      },
      {
        icon: SVGFabric,
        title: 'Fabric',
        description:
          'Made from specially spun fibers that make a very strong and smooth fabric that is perfect for printing',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Without side seams',
        description:
          'Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive',
      },
      {
        icon: SVGRibbedKnitCollarWithoutSeam,
        title: 'Ribbed knit collar without seam',
        description:
          'Ribbed knit makes the collar highly elastic and helps retain its shape',
      },
      {
        icon: SVGShoulderTape,
        title: 'Shoulder tape',
        description:
          'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching',
      },
    ],
    care: {
      icons: [SVGBucket, SVGTriangle, SVGPlug, SVGIronCrossed, SVGRoundCross],
      text: 'Machine wash: warm (max 40C or 105F); Non-chlorine: bleach as needed; Tumble dry: medium; Do not iron; Do not dryclean.',
    },
  },
  '645ab05a25f5aa1f1401bef7': {
    name: 'Unisex Heavy Blend™ Crewneck Sweatshirt',
    features: [
      {
        icon: SVGFiberComposition,
        title: '50% Cotton 50% Polyester',
        description:
          'Made by specially spinned fibers that make very strong and smooth fabric, perfect for printing. Polyester fibers are extremely strong, resistant to most chemicals, stretching and shrinking',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Without side seams',
        description:
          'Knit in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive',
      },
      {
        icon: SVGRibbedKnitCollarWithoutSeam,
        title: 'Ribbed knit collar with seam',
        description:
          'Ribbed knit makes the collar highly elastic and helps retain its shape',
      },
    ],
    care: {
      icons: [
        SVGBucket,
        SVGTriangle,
        SVGWashmachine,
        SVGIronCrossed,
        SVGRoundCross,
      ],
      text: 'Machine wash: cold (max 30C or 90F); Non-chlorine: bleach as needed; Tumble dry: low heat; Do not iron; Do not dryclean.',
    },
  },
  '645aafd4320c4eb39b062764': {
    name: 'Infant Baby Rib Bodysuit',
    features: [
      {
        icon: SVGDoubleRibbedBinding,
        title: 'Double-needle ribbed binding',
        description:
          'Binding on neck, shoulders, sleeves and leg openings provides clean garment lines and prevents stretching',
      },
      {
        icon: SVGLapShoulder,
        title: 'Lap Shoulder',
        description: 'Provides easy dressing experience',
      },
      {
        icon: SVGFiberComposition,
        title: 'Fiber composition',
        description:
          'Solid colors are made of 100% combed ringspun cotton (heather colors are 10% polyester)',
      },
      {
        icon: SVGWithSideSeams,
        title: 'Side seams',
        description: 'Side seams form the structure and shape of the garment',
      },
      {
        icon: SVGRibbedKnit,
        title: 'Rib Knit',
        description:
          'The 1x1 rib knit makes the fabric highly elastic and helps retain its shape',
      },
      {
        icon: SVGThreeSnapClosure,
        title: 'Innovative three-snap closure',
        description: 'Snap tapes result in a soft, smooth closure',
      },
    ],
    care: {
      icons: [
        SVGBucket,
        SVGTriangle,
        SVGWashmachine,
        SVGIronCrossed,
        SVGRoundCross,
      ],
      text: 'Machine wash: cold (max 30C or 90F); Non-chlorine: bleach as needed; Tumble dry: low heat; Do not iron; Do not dry clean.',
    },
  },
  '645aaf85e1ecb5d61d03a8a6': {
    name: 'Sublimation Crew Socks (EU)',
    features: [
      {
        icon: SVGFiberComposition,
        title: '70% Polyester 25% Cotton 5% Spandex',
        description:
          'A polyester exterior and a cotton interior for both superb comfort and printability',
      },
      {
        icon: SVGCrewLength,
        title: 'Crew length',
        description:
          'Perfect to cover up your calf muscles, giving excellent protection and keeping your feet warm',
      },
      {
        icon: SVGVibrantColor,
        title: 'All over print',
        description:
          'Print covers the entire sock to show your design to the fullest',
      },
    ],
    care: {
      icons: [
        SVGRoundCross,
        SVGIronCrossed,
        SVGSquareDash,
        SVGTriangleFilled,
        SVGBucket,
      ],
      text: 'Do not dryclean; Do not iron; Dry flat; Do not bleach; Machine wash: cold (max 30C or 90F).',
    },
  },
  '645aae9f3c0953d4ce05a5d8': {
    name: 'Cork Back Coaster',
    features: [
      {
        icon: SVGGlossyFinish,
        title: 'Glossy Finish',
        description: 'Full-color decoration on one side with glossy finish',
      },
      {
        icon: SVGCorkBottom,
        title: 'Cork Bottom',
        description:
          'The back of the coasters is made from genuine cork, preventing scratches and slipping',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors',
      },
    ],
    care: {
      icons: [],
      text: 'Gently wipe the dirt or dust off with a clean, dry microfiber cloth.',
    },
  },
  '645aae442ede771d560355e8': {
    name: 'Glass Cutting Board',
    features: [
      {
        icon: SVGTemperedGlass,
        title: 'Tempered glass material',
        description:
          'Tempered glass cutting boards make excellent preparation surfaces and are more sanitary than plastic or wood surfaces',
      },
      {
        icon: SVGUniqueDecoration,
        title: 'Unique decoration process',
        description:
          'Unique decoration process allows for durable printing that will not be scratched when used as a cutting surface',
      },
      {
        icon: SVGRubberDots,
        title: '4 rubber dots',
        description: '4 rubber dots keep the board stable and safely in place',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
    ],
    care: {
      icons: [],
      text: 'Use regular dish soap for cleaning and wipe down with a damp cloth.',
    },
  },
  '645aade024762f3482046e38': {
    name: 'Adult Apron (AOP)',
    features: [
      {
        icon: SVGCorkBottom,
        title: '100% polyester canvas',
        description:
          'Fine weave texture fabric and foamed lining makes wearing super soft',
      },
      {
        icon: SVGOverloackSeams,
        title: 'Overlocked seams',
        description:
          'The product is sewn with an overlock, making the seams strong and long-lasting',
      },
      {
        icon: SVGLightweight,
        title: 'Lightweight',
        description:
          'Apron is lightweight, which makes the product even more comfortable to move around in',
      },
      {
        icon: SVGTieBackClosure,
        title: 'Tie back closure',
        description: 'This product has a tie back closure',
      },
    ],
    care: {
      icons: [SVGBucket, SVGTriangleFilled, SVGWashmachine, SVGIronCrossed],
      text: 'Machine wash: cold (max 30C or 90F), *recommended to place a small amount of soap before placing in the washing machine; Do not bleach; Tumble dry: low heat; Do not iron.',
    },
  },
  '645aacf2aec69cb6dd0bd282': {
    name: 'Desk Calendar',
    features: [
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
      {
        icon: SVGFiberComposition,
        title: 'Choose your own style',
        description:
          'The calendars come as blank products, where you have the unique possibility to place your own calendar layout for each month.',
      },
    ],
    care: {
      icons: [],
      text: '',
    },
  },
  '645aac7e27594f330705766e': {
    name: 'Jigsaw Puzzle (30, 110, 252, 500,1000-Piece)',
    features: [
      {
        icon: SVGTemperedGlass,
        title: '30, 110, 252, 500 or 1000 pieces',
        description:
          'Available in 30, 110, 252, 500, or 1000-Piece variations of perfectly interlocking pieces, printed on high quality chipboard with a satin finish',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
    ],
    care: {
      icons: [],
      text: 'Wipe the dirt or dust off gently with a clean, dry microfiber cloth.',
    },
  },
  '645aabda1b4cd7cfd203aca7': {
    name: "Kids' Puzzle, 30-Piece",
    features: [
      {
        icon: SVGMadeForKids,
        title: 'Made for kids',
        description:
          'Large puzzle pieces with rounded corners are great for children to play with',
      },
      {
        icon: SVGBoxWithPuzzle,
        title: 'Box with the puzzle design',
        description:
          'No guesswork - check the reference picture to assemble your puzzle correctly',
      },
      {
        icon: SVGFiberComposition,
        title: 'Chipboard pieces',
        description:
          'Sturdy, yet light - the puzzle pieces are made from chipboard to endure multiple times of assembly',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
    ],
    care: {
      icons: [],
      text: 'Wipe the dirt or dust off gently with a clean, dry microfiber cloth.',
    },
  },
  '645aab33485ed9a4ec0bae07': {
    name: 'Custom Poker Cards',
    features: [
      {
        icon: SVGShuffling,
        title: 'Perfect for dealing & shuffling',
        description: 'The poker cards feature a smooth card stock',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
    ],
    care: {
      icons: [],
      text: 'Use a soft, clean and dry cloth to gently brush any dust or dirt off from the center of the card outwards.',
    },
  },
  '645aaace499be3d354022bb7': {
    name: 'Greeting Cards (1, 10, 30, and 50pcs)',
    features: [
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
      {
        icon: SVGDiamond,
        title: 'Premium quality paper',
        description:
          'The greeting cards are digitally printed on high-quality 270gsm white paper, and are available in the following finishes - uncoated, matte, one-side coated, or two-side coated which will grant you a satin-like finish to your card',
      },
      {
        icon: SVGSustainable,
        title: 'Sustainable product',
        description:
          'The facilities used to produce this product are FSC® and SFI® certified and use sustainable papers',
      },
    ],
    care: {
      icons: [],
      text: 'Use a soft, clean and dry cloth to gently brush any dust or dirt off from the center of the card outwards.',
    },
  },
  '645aaa366c141578830f1c15': {
    name: 'AirPods and AirPods Pro Case Cover',
    features: [
      {
        icon: SVGCrewLength,
        title: 'Flexible material',
        description:
          'The covers are made from premium TPU material, protecting your AirPods against bumps, drops and scratches',
      },
      {
        icon: SVGShuffling,
        title: 'Smooth surface',
        description:
          'AirPods case covers come with an exceptionally smooth surface',
      },
      {
        icon: SVGTieBackClosure,
        title: 'Carabiner hook',
        description:
          'Convenient metal carabiner hook for convenient and secure carrying',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors',
      },
    ],
    care: {
      icons: [],
      text: 'Wipe the dust or any dirt off gently with a clean, dry cloth.',
    },
  },
  '645aa896a6edd54a680558da': {
    name: '20oz Insulated Bottle',
    features: [
      {
        icon: SVGStainlessSteel,
        title: 'Stainless Steel',
        description: 'Resistant to rust, staining and corrosion',
      },
      {
        icon: SVGLeakProof,
        title: 'Leak-proof',
        description: 'Perfect for everyday use',
      },
    ],
    care: {
      icons: [],
      text: 'Hand wash only.',
    },
  },
  '645aa85570637b16dd07ef06': {
    name: 'Square Pillow',
    features: [
      {
        icon: SVGCorkBottom,
        title: '100% polypropylene exterior',
        description:
          'This extremely strong and durable synthetic fabric (faux suede) retains its shape and dries quickly',
      },
      {
        icon: SVGZip,
        title: 'Zipper closure',
        description: 'The product has one white durable zipper on the side',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
      {
        icon: SVGSustainable,
        title: 'Soft and plush',
        description:
          'A plush material that makes it fluffier and soft to the touch',
      },
    ],
    care: {
      icons: [
        SVGHandWash,
        SVGTriangleFilled,
        SVGWashmachineCrossed,
        SVGIronCrossed,
        SVGRoundCross,
      ],
      text: 'Sponge clean only; Do not bleach; Do not tumble dry; Do not iron; Do not dryclean.',
    },
  },
  '645aa7fc4743fca0bf078df6': {
    name: 'Spiral Bound Journal',
    features: [
      {
        icon: SVGRibbedKnit,
        title: 'Metal spiral',
        description: 'Easily removable pages',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'The latest printing techniques provide bright and crisp colors matching your craziest designs',
      },
      {
        icon: SVGGlossyFinish,
        title: 'Glossy laminated cover',
        description: 'Gives the cover a smooth look and durability',
      },
      {
        icon: SVGLightweight,
        title: '90gsm lined paper',
        description: 'Perfect for writing',
      },
    ],
    care: {
      icons: [],
      text: 'Use a soft, clean and dry cloth to gently brush any dust or dirt off from the center of book outwards.',
    },
  },
  '645aa7bb38b85c9f3802c79b': {
    name: 'Kiss-Cut Stickers',
    features: [
      {
        icon: SVGGlossyFinish,
        title: 'Glossy paper finishe',
        description: 'Scratch-resistant surface',
      },
      {
        icon: SVGMadeForKids,
        title: 'Long lasting',
        description:
          'Vinyl surface stickers with a permanent acrylic adhesive backside, perfect for smooth flat surfaces (like laptops, journals, windows etc.)',
      },
      {
        icon: SVGVibrantColor,
        title: 'Vibrant colors',
        description:
          'Printed with environmentally friendly eco-solvent inks provides extraordinary bright colors',
      },
    ],
    care: {
      icons: [],
      text: 'Use a soft, clean and dry cloth to gently brush any dust or dirt off from the center of the sticker outwards.',
    },
  },
  '645aa5873623f60eb30a3f1a': {
    name: 'Poker Cards',
    features: [
      {
        icon: SVGFabric,
        title: 'UV protective laminate',
        description:
          'To protect your cards from wearing down, a layer of matte laminate is applied to each card.',
      },
      {
        icon: SVGCrewLength,
        title: 'One design for the set',
        description:
          'Choose your text, logo or image to be printed on the back of the whole deck.',
      },
      {
        icon: SVGDiamond,
        title: 'Premium quality 300gsm paper',
        description:
          'The cards are printed on a thick paper stock, providing a premium feel and increased durability.',
      },
      {
        icon: SVGDishwasher,
        title: 'Classic poker card set',
        description:
          'The deck includes 52 cards and two jokers. The cards are packaged in a white box.',
      },
    ],
    care: {
      icons: [],
      text: 'Use a soft, clean and dry cloth to gently brush any dust or dirt off from the center of the card outwards.',
    },
  },
  '64453fa1b741ae3e6109a5c9': {
    name: 'Infant Fine Jersey Tee',
    features: [
      {
        icon: SVGFabric,
        title: 'Fabric',
        description:
          'Made from specially spun fibers that make very strong and smooth fabric, perfect for printing',
      },
      {
        icon: SVGWithSideSeams,
        title: 'With side seams',
        description:
          "Located along the sides, they help hold the garment's shape longer and give it structural support",
      },
      {
        icon: SVGShoulderTape,
        title: 'Ribbed knit collar with seam',
        description:
          'Ribbed knit makes the collar highly elastic and helps retain its shape',
      },
      {
        icon: SVGFiberComposition,
        title: 'Fiber composition',
        description:
          'Solid colors are 100% cotton; Heather colors are 60% cotton, 40% polyester (Heather is 90% cotton, 10% polyester)',
      },
      {
        icon: SVGRibbedKnitCollarWithoutSeam,
        title: 'Shoulder tape',
        description:
          'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching',
      },
    ],
    care: {
      icons: [
        SVGBucketWithStand,
        SVGTriangle,
        SVGWashmachine,
        SVGIron,
        SVGRoundCross,
      ],
      text: 'Machine wash: cold (max 30C or 90F); Non-chlorine: bleach as needed; Tumble dry: low heat; Iron, steam or dry: low heat; Do not dry clean.',
    },
  },
  '64453ef169c0c405980b7c5a': {
    name: 'Kids Heavy Cotton™ Tee',
    features: [
      {
        icon: SVGFiberComposition,
        title: 'Fiber composition',
        description:
          'Solid colors are 100% cotton, heather colors are 50% cotton, 50% polyester, and Sport Grey is 90% cotton, 10% polyester.',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Without side seams',
        description:
          'Knit in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Ribbed knit collar without seam',
        description:
          'Ribbed knit makes the collar highly elastic and helps retain its shape',
      },
      {
        icon: SVGRibbedKnitCollarWithoutSeam,
        title: 'Shoulder tape',
        description:
          'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching',
      },
    ],
    care: {
      icons: [SVGBucket, SVGTriangle, SVGWashmachine, SVGIron, SVGRoundCross],
      text: 'Machine wash: warm (max 40C or 105F); Non-chlorine: bleach as needed; Tumble dry: medium heat; Iron, steam or dry: low heat; Do not dry clean.',
    },
  },
  '64453e59fa3bf3d6c50154da': {
    name: 'Unisex Heavy Blend™ Hooded Sweatshirt',
    features: [
      {
        icon: SVGFiberComposition,
        title: '50% cotton, 50% polyester',
        description:
          'Made from specially spun fibers that make for a very strong, smooth fabric which is perfect for printing. Polyester fibers are extremely resilient, resistant to most chemicals, stretching, and shrinking. Heather Sport colors are 40% Cotton, 60% Polyester',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Without side seams',
        description:
          'Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive',
      },
      {
        icon: SVGDrawStringHood,
        title: 'Drawstring hood',
        description: 'Adjustable hood with self-colored woven cord',
      },
      {
        icon: SVGSpaciousPocket,
        title: 'Spacious pockets',
        description: 'Kangaroo pouch pocket will always keep your hands warm',
      },
    ],
    care: {
      icons: [
        SVGBucket,
        SVGTriangleOutline,
        SVGWashmachine,
        SVGIron,
        SVGRoundCross,
      ],
      text: 'Machine wash: warm (max 40C or 105F); Bleach as needed; Tumble dry: medium; Iron, steam or dry: low heat; Do not dryclean.',
    },
  },
  '64368bcbbed6f124fe036167': {
    name: 'Unisex Heavy Cotton Tee',
    features: [
      {
        icon: SVGFiberComposition,
        title: 'Fiber composition',
        description:
          'Solid colors are 100% cotton; Heather colors are 50% cotton, 50% polyester (Sport Grey and Antique colors are 90% cotton, 10% polyester. Ash is 99% cotton, 1% polyester)',
      },
      {
        icon: SVGFabric,
        title: 'Fabric',
        description:
          'Made from specially spun fibers that make a very strong and smooth fabric that is perfect for printing',
      },
      {
        icon: SVGWithoutSideSeams,
        title: 'Without side seams',
        description:
          'Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive',
      },
      {
        icon: SVGShoulderTape,
        title: 'Ribbed knit collar without seam',
        description:
          'Ribbed knit makes the collar highly elastic and helps retain its shape',
      },
      {
        icon: SVGRibbedKnitCollarWithoutSeam,
        title: 'Shoulder tape',
        description:
          'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching',
      },
    ],
    care: {
      icons: [
        SVGBucket,
        SVGTriangle,
        SVGWashmachine,
        SVGIronCrossed,
        SVGRoundCross,
      ],
      text: 'Machine wash: warm (max 40C or 105F); Non-chlorine: bleach as needed; Tumble dry: medium; Do not iron; Do not dryclean.',
    },
  },
}
