import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

import { EventCard, BasketSummary } from '../../components'
import { useFetch } from '../../utils/hooks'
import { EventType, MainEvent } from '../../types'
import { useCart } from '../../utils/contexts'
import './tickets.scss'
import { CartItemTypeEnum } from '../../utils/contexts/CartContext/cartContext.type'

const TestEventIds: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const Tickets = () => {
  const { data: mainEventsData, error: eventsError } = useFetch<{
    data: MainEvent[]
  }>('/events')
  const { cart, isPrintifyProductInCart } = useCart()
  const navigate = useNavigate()

  const [totalPrice, setTotalPrice] = useState<number>(0)

  const setCartItems = () => {
    if (cart) {
      setTotalPrice(() => 0)

      cart.items.forEach((cartItem) => {
        setTotalPrice(
          (totalPrice) =>
            totalPrice +
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        )
      })
    }
  }

  useEffect(() => {
    if (cart) {
      setCartItems()
    }
  }, [cart])

  useEffect(() => {
    if (eventsError) {
      toast.error('Error fetching events. Please try again later.')
    }
  }, [eventsError])

  const getCheckoutLink: string = useMemo(() => {
    if (cart) {
      const isTicketsInCart = cart.items.some(
        (item) => item.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
      )

      if (isPrintifyProductInCart) {
        return '/checkout/shipping-address'
      }

      if (isTicketsInCart) {
        return '/checkout/overview'
      }

      return '/exhibitor-shop/checkout'
    }

    return ''
  }, [cart])

  const checkClick = () => {
    navigate(getCheckoutLink)
  }

  const renderSubEvents = (subEvents: EventType[]) => {
    return subEvents.map((event) => <EventCard key={event.id} event={event} />)
  }

  return (
    <Container>
      <Row>
        <Col xl={1} className="d-none d-xl-block" />
        <Col lg={8} xl={7}>
          {mainEventsData?.data.map((mainEvent) => (
            <Fragment key={mainEvent.id}>
              <h2>{mainEvent.name}</h2>
              {mainEvent.sub_events.map((event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </Fragment>
          ))}
        </Col>
        <Col lg={4} xl={3} className="mb-3 mb-lg-0">
          <BasketSummary />
        </Col>
        <Col xl={1} className="d-none d-xl-block" />
      </Row>
      <Row className="subtotal d-flex d-lg-none position-fixed p-3 bg-white align-items-center">
        <Col xs={6} sm={6} md={0}>
          Subtotal: <span className="fw-semibold">£{totalPrice}</span>
        </Col>
        <Col xs={6} sm={6} md={0} className="text-end">
          <Button
            variant="primary"
            className="rounded-pill"
            onClick={checkClick}
            disabled={cart?.items.length === 0}
          >
            Checkout
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
