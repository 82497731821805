/* eslint-disable react/no-unescaped-entities */

import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

import { PageTitle } from '../../components'

export const TermsConditions = () => {
  return (
    <>
      <PageTitle title="Terms and Conditions" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto">
          <br />
          <strong>GENERAL TERMS</strong>
          <br />
          1. “<strong>Event</strong>” shall mean Navrangi Navratri 2023..
          <br />
          <br />
          2. The Event, held at The Drum, Wembley, London, HA9 0FJ (“Venue”) is
          organised and managed by Red Lotus Events CIC (UK) (“Organiser”), a
          company incorporated in England and Wales with company number 10624098
          and with registered office at 99a Cobbold Road, London, England, NW10
          9SL. Visitors can attend the Event either with: (a) a general
          admission ticket (“General Ticket”); which allows access
          <br />
          <br />
          3. A valid Event Ticket entitles the holder to attend the Event on the
          date and at the times stated on the Event Ticket.
          <br />
          <br />
          4. Notwithstanding the means by which a visitor holds an Event Ticket,
          the General Terms, Entry Conditions, and Refund Terms (to the extent
          set out therein) apply to all visitors attending the Event.
          <br />
          <br />
          5. We respect your Personal Data and shall deal with it in accordance
          with our Privacy Policy and applicable UK Data Protection legislation
          in place from time to time. Please see our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link> for further details.
          <br />
          <br />
          <strong>B. ENTRY CONDITIONS</strong>
          <br />
          <strong>Event Tickets and Admission</strong>
          <br />
          1. There shall be no admittance to the Event without a valid Event
          Ticket.
          <br />
          <br />
          2. All holders of an Event Ticket agree to be bound by these Entry
          Conditions, those as detailed on the Event Ticket, and such other
          terms as are drawn to the attention of visitors by the Organiser
          and/or by any third party seller of Event Tickets (as applicable).
          <br />
          <br />
          3. It is the Event Ticket holder’s responsibility to check his or her
          Event Ticket(s), as mistakes cannot always be rectified and the
          Organiser shall not be responsible for any incorrect Event Tickets.
          <br />
          <br />
          4. Event Tickets may not be used as prizes in competitions,
          “give-aways” or a lottery without the prior written consent of the
          Organiser in its sole discretion.
          <br />
          <br />
          5. Event Tickets must not be resold, exchanged, transferred, altered
          or defaced. Any such action shall render the Event Ticket(s) void (and
          any individual with such an Event Ticket may be refused entry or
          removed from the Event in Organiser’s sole discretion).
          <br />
          <br />
          6. On entry to the Event all visitors agree to accept and abide by any
          venue rules of The Drum and as set out by Red Lotus Events CIC, (as
          may be updated from time to time) and any other regulations from time
          to time provided on entry to the Venue.
          <br />
          <br />
          7.The Organiser shall not be responsible for any lost or stolen Event
          Tickets.
          <br />
          <br />
          8. All Event Ticket sales are final and unless otherwise stated herein
          (or in any Third Party Terms, if applicable), no refunds,
          cancellations or exchanges are available after an Event Ticket has
          been purchased.
          <br />
          <br />
          9. The concerts at the Event (“Concerts”) may be seated or standing
          and such designation will be detailed on the Event Ticket.
          <br />
          <br />
          10.Event Tickets are issued subject to, and these Entry Conditions
          incorporate, the rules and regulations of the Brent Council and
          organisers band shall remain the property of the Organiser who hereby
          reserves the right to recall any Event Ticket at any time.
          <br />
          <br />
          12. The concerts at the Event (“Concerts”) may be seated or standing
          and such designation will be detailed on the Event Ticket.
          <br />
          <br />
          13. Event Tickets are issued subject to, and these Entry Conditions
          incorporate, the rules and regulations of the Brent Council and
          organisers band shall remain the property of the Organiser who hereby
          reserves the right to recall any Event Ticket at any time.
          <br />
          <br />
          14. Without prejudice to the Refund Terms and any third Party Terms
          (if applicable), Event Tickets are non-refundable and are issued
          subject to the Organiser’s right to alter or vary the Event due to
          events or circumstances beyond its reasonable control without being
          obliged to refund monies or exchange Event Tickets. It is the
          responsibility of the Event Ticket holder to ascertain the date, time
          and venue of any rearranged of the Event.
          <br />
          <br />
          15. Organiser reserves the rights to refuse admission to the Event to
          anyone who does not have a valid Event Ticket (including those which
          appear to be counterfeit or which have been altered and/or defaced).
          <br />
          <br />
          16. You are not entitled to purchase any Tickets as a trader acting in
          the course of business with the intention of reselling your Tickets
          for profit. If we discover or have reason to suspect that you have
          purchased and intend to resell, or have sold Tickets in breach of this
          clause, we reserve the right to cancel your Tickets without notice.
          <br />
          <br />
          17. You may not resell or transfer your Tickets if prohibited by law.
          In addition, Event Partners may restrict or prohibit the resale or
          transfer of tickets for some events. Any resale or transfer (or
          attempted resale or transfer) of a ticket in breach of the applicable
          law or any restrictions or prohibition imposed by an Event Partner is
          grounds for seizure or cancellation of that Ticket.
          <br />
          <br />
          <strong>Event and Venue Rules</strong>
          <br />
          1. In order to facilitate the security, safety and comfort of all
          attendees, Organisers, the event security team and The Brent Council
          reserve the right in their absolute discretion to conduct security
          searches of persons, clothing, bags and all other items on entry and
          exit to the Event and to confiscate items which may be illegal, cause
          danger and/or disruption to the Event or other visitors, or are in
          breach of these Entry Conditions and to refuse admission to the Event.
          Anyone resisting confiscation of disallowed items or disregarding
          these conditions will face eviction from the Venue. 23. Organiser
          reserves the right to:
          <br />
          <span className="ms-3 d-block">
            1.1. evict any person who is:
            <span className="ms-3 d-block">
              1.1.1. breaching these Entry Conditions;
            </span>
            <span className="ms-3 d-block">
              1.1.2. committing a criminal offence;
            </span>
            <span className="ms-3 d-block">
              1.1.3. behaving in a disorderly manner or in a way that has an
              adverse effect on public safety,
            </span>
            <span className="ms-3 d-block">
              1.1.4. behaving in an anti-social manner or in a way that causes a
              public nuisance;
            </span>
            <span className="ms-3 d-block">
              1.1.5. conducting themselves in a manner that is inappropriate to
              and/or compromises the safety and/or security of other visitors;
            </span>
            <span className="ms-3 d-block">
              1.1.6. conducting themselves in a manner that is offensive and/or
              disrespectful to staff; and/or
            </span>
            <span className="ms-3 d-block">
              1.1.7. under the influence of drugs and/or excessive alcohol;
              and/or
            </span>
          </span>
          <span className="ms-3 d-block">
            1.2. refuse alcohol to any attendee who, in Organiser’s absolute
            discretion, is unreasonably intoxicated and/or is behaving in an
            unacceptable manner;
          </span>
          <span className="ms-3 d-block">
            1.3. evict all members of any group of persons where that group is
            behaving in a persistent anti-social manner.
          </span>
          <br />
          <br />
          2. In the event of an emergency, Event Ticket holders and all visitors
          to the Event must follow instructions and directions from stewards,
          staff and/or other officials.
          <br />
          <br />
          3. The Event is an outdoor event and visitors are advised to wear
          appropriate clothing and footwear at all times.
          <br />
          <br />
          4. All children and infants must have a Ticket in order to attend an
          Event – unless otherwise specified on the Event details page, during
          the booking process or on the Ticket Terms.
          <br />
          <br />
          5. It is your responsibility to check the suitability of an Event for
          anyone in your party regardless. This includes considering the type of
          audience the Event is likely to attract. Refunds will not be issued if
          any aspect of an Event, including the behaviour of other audience
          members, causes offence to you or any children on whose behalf you
          have purchased Tickets.
          <br />
          <br />
          6. All children are to be accompanied by an Adult. Accompanying adults
          should be confident that children within their party will not cause
          disruption to other audience members, and take responsibility of the
          safety of any children in their party. Any disruption may result in
          you and your children being removed from the Venue.
          <br />
          <br />
          <strong>Prohibited Acts and Items</strong>
          <br />
          1. Visitors must not bring any of the following items into the Venue:
          <span className="ms-3 d-block">1.1. Sound systems</span>
          <span className="ms-3 d-block">1.2. BBQs</span>
          <span className="ms-3 d-block">
            1.3. No food, soft drinks and alcohol can be brought into the Event
            unless it is to accommodate access/medical requirements. Please
            note, this is permitted at the discretion of security.
          </span>
          <span className="ms-3 d-block">
            1.4. No items in glass bottles. These will be confiscated if found.
          </span>
          <span className="ms-3 d-block">
            1.5. No animals (except registered guide/assistance dogs). Please
            inform organisers prior to arriving onsite.
          </span>
          <span className="ms-3 d-block">
            1.6. Any item which may reasonably be considered for use as a weapon
            by the Organisers
          </span>
          <span className="ms-3 d-block">
            1.7. You may be searched at the entrance for any items that may be
            used in an illegal or offensive manner – which will be confiscated.
            Persons suspected of carrying out illegal and/or offensive
            activities onsite may also be searched and face eviction and may be
            reported to the authorities.
          </span>
          <span className="ms-3 d-block">1.8. Illegal substances</span>
          <span className="ms-3 d-block">
            1.9. No Nitrous oxide (laughing gas). As a contraband good, under
            the Psychoactive Substances Act 2016, this substance is banned.
          </span>
          <span className="ms-3 d-block">
            1.10. Flying or filming with Unmanned Aerial Vehicles (UAVs, or
            so-called drones) is not allowed on site before, during or after the
            festival – and is prohibited by law.
          </span>
          <span className="ms-3 d-block">
            1.11. Unauthorised items for trading, including any goods using
            unauthorised event logos.
          </span>
          <span className="ms-3 d-block">
            1.12. Unofficial tabards or reflective jackets
          </span>
          <span className="ms-3 d-block">
            1.13. Electric skateboards, scooters, and bicycles are not
            permitted.
          </span>
          <span className="ms-3 d-block">1.14. Aerosols over 250ml</span>
          <span className="ms-3 d-block">1.15. Megaphones</span>
          <span className="ms-3 d-block">1.16. Air horns</span>
          <span className="ms-3 d-block">
            1.17. Persons using fireworks or Chinese Lanterns, Flares or
            Generators will be evicted from site and materials confiscated.
            Fires, flares, fireworks etc will be extinguished by the onsite fire
            service.
          </span>
          <span className="ms-3 d-block">
            1.18. No portable laser equipment or pens are permitted.
          </span>
          <span className="ms-3 d-block">1.19. Blow torches</span>
          <span className="ms-3 d-block">1.20. Kites</span>
          <span className="ms-3 d-block">1.21. Drums</span>
          <span className="ms-3 d-block">
            1.22. Any item or part of an item that Organiser or Brent Council
            considers to be potentially harmful to public safety
          </span>
          <span className="ms-3 d-block">
            1.23. No blow up lay sacks or large seating items or chairs are
            allowed.
          </span>
          <span className="ms-3 d-block">
            1.24. Any photography equipment that could be used for ‘professional
            photography’
          </span>
          <span className="ms-3 d-block">
            1.25. Visitors must only use the toilets and urinals provided and
            must not pollute the land
          </span>
          <span className="ms-3 d-block">
            1.26. Unauthorised professional tape recorders, photography, and
            video equipment. Equipment considered to be professional will be
            permitted at the discretion of the security, any other items
            considered to be unsafe or potentially posing a risk to public
            safety and/ or security.
          </span>
          <br />
          <br />
          2. Organiser reserves the right to confiscate any such item(s) from
          any person entering or at the Venue.
          <br />
          <br />
          3. Smoking or the use of electronic cigarettes is not permitted in the
          festival ground and is only permitted at designated outdoor areas at
          the Venue.
          <br />
          <br />
          4. Bag searches will be in operation. We do not allow large bags into
          the arena for security reasons.
          <br />
          <br />
          33. Use of illegal substances is strictly prohibited at the Event.
          <br />
          <br />
          5. Commercial, promotional and trading activities are not permitted at
          the Event without express prior written permission of the Organiser.
          Please note that the Organiser will charge a fee for any commercial
          activity (including filming, photography and recording) at the Event.
          <br />
          <br />
          <strong>Filming and Photography</strong>
          <br />
          1. All sound and moving or still picture rights including, without
          limitation, on the internet, vest exclusively with the Organiser and
          any material captured, filmed or recorded at the Event by any Event
          goer may only be used for the personal, non-profit making enjoyment of
          amateurs.
          <br />
          <br />
          2. By entering the Event, visitors agree and consent to:
          <span className="ms-3 d-block">
            2.1. being photographed, filmed or recorded as visitors attending
            the Event, and to such image(s) or recording(s) of them being used
            in future marketing and promotional material for the Event and/or by
            the Organiser and its affiliated companies
          </span>
          <span className="ms-3 d-block">
            2.2. being filmed, photographed and recorded for television, radio,
            webcast and other public broadcast in any medium and/or for any
            video or DVD as part of the audience.
          </span>
          <br />
          <br />
          3. Entrance to the Event is subject to both a prohibition on the use
          of photographic or filming equipment at the Event for commercial gain,
          without the Organiser’s prior written consent; such that:
          <span className="ms-3 d-block">
            3.1. a person may be denied entry to, or removed from the Event if
            he or she is found in possession of any equipment capable of taking
            photographs, making films, sound recordings or broadcasts; or if he
            or she takes any photograph, makes a film, sound recording or
            broadcast of anything occurring inside the Event for commercial
            gain; and
          </span>
          <span className="ms-3 d-block">
            3.2. a person may enter the Event with equipment referred to in (a)
            above where it is integrated into electronic communications
            apparatus designed for personal private use (such as a mobile
            telephone) and/or a personal stills camera, but the person may still
            be removed in accordance with paragraph (a) above if they attempt to
            use this device for commercial gain.
          </span>
          <br />
          <br />
          <strong>Miscellaneous</strong>
          <br />
          1. Each holder of an Event Ticket acknowledges that attendance at the
          Event is entirely at their own risk and neither the Organiser nor any
          of its employees, contractors, affiliated entities, or their servants
          or agents, shall be liable for any loss, injury or damage (to person
          or property) howsoever suffered by a holder of an Event Ticket,
          including consequential losses, save for:
          <br />
          <span className="ms-3 d-block">
            1.1. death or personal injury caused by the negligence of the
            Organiser, its employees, contractors or agents; and
          </span>
          <span className="ms-3 d-block">
            2.2. any other liability that cannot be excluded or limited under
            applicable laws, including the Consumer Rights Act 2015.
          </span>
          <br />
          <br />
          2. Each Event goer is responsible for any travel and accommodation
          arrangements for the Event and except as set out in the Refund Terms,
          Organiser shall not be liable for loss or damage of any kind whether
          direct or consequential, including but not limited to travel or
          accommodation expenses from postponement, cancellation, abandonment or
          curtailment of the Event and the Event Ticket holder waives all rights
          of whatsoever nature against Organiser, its employees, servants,
          agents, independent contractors or representatives arising from
          cancellation, abandonment or curtailment of the Event. Nothing in the
          Terms and Conditions shall exclude or restrict liability for personal
          injury or death due to negligence.
          <br />
          <br />
          3. These Entry Conditions shall be governed by English law and those
          bound by them hereby submit to the exclusive jurisdiction of the
          English courts in connection herewith.
          <br />
          <br />
          4. If any of these Entry Conditions should be determined to be
          illegal, invalid or otherwise unenforceable by reason of the law of
          any state or country in which these terms and conditions are intended
          to be effective, then to the extent and within the jurisdiction in
          which that term or condition is illegal, invalid or unenforceable, it
          shall be severed and deleted from that term and the remaining terms
          and conditions shall survive and continue to be binding and
          enforceable.
          <br />
          <br />
          5. The Organiser expressly reserves the right to change and/or amend
          these Entry Conditions from time to time.
          <br />
          <br />
        </p>
      </Container>
    </>
  )
}
