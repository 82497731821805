import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { PageTitle } from '../../components'

const SHOW_EXHIBITOR_SHOP = 'RG_STALLS_2023'
const SHOW_PASSWORD = 'isShopAvailable'

export const ExhibitorRoot = () => {
  const [password, setPassword] = useState('')
  const [showShop, setShowShop] = useState(false)

  useEffect(() => {
    const data = sessionStorage.getItem(SHOW_PASSWORD)
    if (data && data === '1') {
      setShowShop(true)
    }
  }, [])

  const handlePasswordSubmit = () => {
    if (password === SHOW_EXHIBITOR_SHOP) {
      setShowShop(true)
      sessionStorage.setItem(SHOW_PASSWORD, '1')
    } else {
      toast.error('Oops, Incorrect password, please try again.')
    }
  }

  return (
    <>
      <PageTitle title="Exhibitor Shop" />
      {showShop ? (
        <Outlet />
      ) : (
        <Container>
          <Row>
            <Col md={4} />
            <Col md={4}>
              <div className="d-flex my-3 py-4">
                <input
                  className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Enter password"
                />
                <Button
                  variant="secondary"
                  className="ms-3"
                  onClick={handlePasswordSubmit}
                >
                  Submit
                </Button>
              </div>
            </Col>
            <Col md={4} />
          </Row>
        </Container>
      )}
    </>
  )
}
