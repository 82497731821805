/* eslint-disable react/no-unescaped-entities */

import Container from 'react-bootstrap/Container'
import { PageTitle } from '../../components'

export const PrivacyPolicy = () => {
  return (
    <>
      <PageTitle title="Privacy Policy" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto">
          <br />
          This privacy policy is for rangeelugujarat.com and served by Red Lotus
          Events CIC and governs the privacy of its users who choose to use it.
          <br />
          <br />
          The policy sets out the different areas where user privacy is
          concerned and outlines the obligations & requirements of the users,
          the website and website owners. Furthermore the way this website
          processes, stores and protects user data and information will also be
          detailed within this policy.
          <br />
          <br />
          <strong>The Website</strong>
          <br />
          This website and its owners take a proactive approach to user privacy
          and ensure the necessary steps are taken to protect the privacy of its
          users throughout their visiting experience. This website complies to
          all UK national laws and requirements for user privacy.
          <br />
          <br />
          <strong>Use of Cookies</strong>
          <br />
          This website uses cookies to better the users experience while
          visiting the website. Where applicable this website uses a cookie
          control system allowing the user on their first visit to the website
          to allow or disallow the use of cookies on their computer / device.
          This complies with recent legislation requirements for websites to
          obtain explicit consent from users before leaving behind or reading
          files such as cookies on a user's computer / device.
          <br />
          <br />
          Cookies are small files saved to the user's computer's hard drive that
          track, save and store information about the user's interactions and
          usage of the website. This allows the website, through its server to
          provide the users with a tailored experience within this website.
          <br />
          <br />
          Users are advised that if they wish to deny the use and saving of
          cookies from this website on to their computers hard drive they should
          take necessary steps within their web browsers security settings to
          block all cookies from this website and its external serving vendors.
          <br />
          <br />
          This website uses tracking software to monitor its visitors to better
          understand how they use it. This software is provided by Google
          Analytics which uses cookies to track visitor usage. The software will
          save a cookie to your computer's hard drive in order to track and
          monitor your engagement and usage of the website, but will not store,
          save or collect personal information.
          <br />
          <br />
          Other cookies may be stored to your computer's hard drive by external
          vendors when this website uses referral programs, sponsored links or
          adverts. Such cookies are used for conversion and referral tracking
          and typically expire after 30 days, though some may take longer. No
          personal information is stored, saved or collected.
          <br />
          <br />
          <strong>Contact & Communication</strong>
          <br />
          Users contacting this website and/or its owners do so at their own
          discretion and provide any such personal details requested at their
          own risk. Your personal information is kept private and stored
          securely until a time it is no longer required or has no use, as
          detailed in the Data Protection Act 1998. Every effort has been made
          to ensure a safe and secure form to email submission process but
          advise users using such form to email processes that they do so at
          their own risk.
          <br />
          <br />
          This website and its owners use any information submitted to provide
          you with further information about the products / services they offer
          or to assist you in answering any questions or queries you may have
          submitted. This includes using your details to subscribe you to any
          email newsletter program the website operates but only if this was
          made clear to you and your express permission was granted when
          submitting any form to email process. Or whereby you the consumer have
          previously purchased from or enquired about purchasing from the
          company a product or service that the email newsletter relates to.
          This is by no means an entire list of your user rights in regard to
          receiving email marketing material. Your details are not passed on to
          any third parties.
          <br />
          <br />
          <strong>Email Newsletter</strong>
          <br />
          This website operates an email newsletter program, used to inform
          subscribers about products and services supplied by this website.
          Users can subscribe through an online automated process should they
          wish to do so but do so at their own discretion. Some subscriptions
          may be manually processed through prior written agreement with the
          user.
          <br />
          <br />
          Subscriptions are taken in compliance with UK Spam Laws detailed in
          the Privacy and Electronic Communications Regulations 2003. All
          personal details relating to subscriptions are held securely and in
          accordance with the Data Protection Act 1998. No personal details are
          passed on to third parties nor shared with companies / people outside
          of the company that operates this website. Under the Data Protection
          Act 1998 you may request a copy of personal information held about you
          by this website's email newsletter program. A small fee will be
          payable. If you would like a copy of the information held on you
          please write to the business address at the bottom of this policy.
          <br />
          <br />
          Email marketing campaigns published by this website or its owners may
          contain tracking facilities within the actual email. Subscriber
          activity is tracked and stored in a database for future analysis and
          evaluation. Such tracked activity may include; the opening of emails,
          forwarding of emails, the clicking of links within the email content,
          times, dates and frequency of activity [this is by no far a
          comprehensive list]. This information is used to refine future email
          campaigns and supply the user with more relevant content based around
          their activity.
          <br />
          <br />
          In compliance with UK Spam Laws and the Privacy and Electronic
          Communications Regulations 2003 subscribers are given the opportunity
          to un-subscribe at any time through an automated system. This process
          is detailed at the footer of each email campaign. If an automated
          un-subscription system is unavailable clear instructions on how to
          un-subscribe will by detailed instead.
          <br />
          <br />
          <strong>External Links</strong>
          <br />
          Although this website only looks to include quality, safe and relevant
          external links, users are advised to adopt a policy of caution before
          clicking any external web links mentioned throughout this website.
          (External links are clickable text / banner / image links to other
          websites.
          <br />
          <br />
          The owners of this website cannot guarantee or verify the contents of
          any externally linked website despite their best efforts. Users should
          therefore note they click on external links at their own risk and this
          website and its owners cannot be held liable for any damages or
          implications caused by visiting any external links mentioned.
          <br />
          <br />
          <strong>Adverts and Sponsored Links</strong>
          <br />
          This website may contain sponsored links and adverts. These will
          typically be served through our advertising partners, to whom may have
          detailed privacy policies relating directly to the adverts they serve.
          <br />
          <br />
          Clicking on any such adverts will send you to the advertisers website
          through a referral program which may use cookies and will track the
          number of referrals sent from this website. This may include the use
          of cookies which may in turn be saved on your computer's hard drive.
          Users should therefore note they click on sponsored external links at
          their own risk and this website and its owners cannot be held liable
          for any damages or implications caused by visiting any external links
          mentioned.
          <br />
          <br />
          <strong>Social Media Platforms</strong>
          <br />
          Communication, engagement and actions taken through external social
          media platforms that this website and its owners participate on are
          custom to the terms and conditions as well as the privacy policies
          held with each social media platform respectively.
          <br />
          Users are advised to use social media platforms wisely and communicate
          / engage upon them with due care and caution in regard to their own
          privacy and personal details. This website nor its owners will ever
          ask for personal or sensitive information through social media
          platforms and encourage users wishing to discuss sensitive details to
          contact them through primary communication channels such as by
          telephone or email.
          <br />
          <br />
          This website may use social sharing buttons which help share web
          content directly from web pages to the social media platform in
          question. Users are advised before using such social sharing buttons
          that they do so at their own discretion and note that the social media
          platform may track and save your request to share a web page
          respectively through your social media platform account.
          <br />
          <br />
          <strong>Shortened Links in Social Media</strong>
          <br />
          This website and its owners through their social media platform
          accounts may share web links to relevant web pages. By default some
          social media platforms shorten lengthy urls [web addresses] (this is
          an example: https://rb.gy/jn4fp.
          <br />
          <br />
          Users are advised to take caution and good judgement before clicking
          any shortened urls published on social media platforms by this website
          and its owners. Despite the best efforts to ensure only genuine urls
          are published, many social media platforms are prone to spam and
          hacking and therefore this website and its owners cannot be held
          liable for any damages or implications caused by visiting any
          shortened links.
        </p>
      </Container>
    </>
  )
}
