import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import {
  EventType,
  PrintifyProductType,
  ProductType,
  TicketType,
} from '../../../types'
import {
  useAuthentication,
  useCart,
  useDeviceType,
} from '../../../utils/contexts'
import { PageTitle, QuantityPicker } from '../../../components'
import {
  CartItemProductTypeEnum,
  CartItemType,
  CartItemTypeEnum,
} from '../../../utils/contexts/CartContext/cartContext.type'
import './checkoutOverview.scss'
import { getQuantityPrice } from '../../../utils/helpers'
import { CART_ITEM_TYPE_TICKET } from '../../../utils/constants'
import { usePaymentType } from '../../../utils/contexts/PaymentContext'

type NewCartType = CartItemType & {
  displayPrice: string
  displayQuantity: number
}

type FilteredListType = {
  [key: string]: NewCartType[]
}

type CheckoutProps = {
  applyVat?: boolean
}

const PaymentOptions = [
  { name: 'Full Payment', value: '1' },
  { name: 'Partial Payment', value: '2' },
]

export const CheckoutOverview = ({ applyVat = false }: CheckoutProps) => {
  const {
    cart,
    updateItem,
    deleteCartItem,
    applyCoupon,
    afterCouponTotal,
    shippingCost,
    appliedCoupon,
    removeCoupon,
    getCart,
    setAppliedCoupon,
  } = useCart()
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useDeviceType()

  const [sortedItems, setSortedItems] = useState<FilteredListType>({})
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [discountCode, setDiscountCode] = useState('')
  const [paymentOption, setPaymentOption] = useState(PaymentOptions[0].value)
  const [donationAmount, setDonationAmount] = useState<number>(5)
  const [isPartialPayment, setIsPartialPayment] = useState(false)

  useEffect(() => {
    if (paymentOption === PaymentOptions[1].value) {
      setIsPartialPayment(true)
    }
  }, [paymentOption])

  useEffect(() => {
    if (applyVat || location.state?.applyVat) {
      setDonationAmount(0)
    }
  }, [applyVat, location])

  const setCartItems = () => {
    if (cart) {
      if (cart?.items.length === 0) {
        navigate('/tickets', { replace: true })
      }
      setSubTotalPrice(() => 0)
      const filteredList: FilteredListType = {}
      let appliedCouponInCart = ''

      cart.items.forEach((cartItem) => {
        if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
          (cartItem.item as PrintifyProductType).product_type ===
            CartItemProductTypeEnum.PRODUCT_TYPE_PRINTIFY &&
          !shippingCost
        ) {
          navigate('/checkout/shipping-address')
        }

        if (cartItem.discount_coupan) {
          appliedCouponInCart = cartItem.discount_coupan
        }

        const cartItemPrice =
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
            ? cartItem.total
            : Number.parseFloat(`${cartItem.price_per_unit}`) *
              cartItem.quantity

        setSubTotalPrice((subTotalPrice) => subTotalPrice + cartItemPrice)

        const { newQuantity, newPricePerUnit } = getQuantityPrice(cartItem)

        const localCartItem: NewCartType = {
          ...cartItem,
          displayPrice:
            cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
              ? `${cartItem.item.price}`
              : `${newPricePerUnit}`,
          displayQuantity: newQuantity,
        }

        if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
          (cartItem.item as ProductType).product_type ===
            CartItemProductTypeEnum.PRODUCT_TYPE_STALL
        ) {
          setIsPartialPayment(true)
        }

        let key = ''

        if (cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
          key = (cartItem.item as TicketType).event?.name || 'Tickets'
        } else if (
          (cartItem.item as ProductType).category.name === 'Printify'
        ) {
          key = 'Merchandise'
        } else {
          key = (cartItem.item as ProductType).category.name
        }

        if (filteredList[key]) {
          filteredList[key].push(localCartItem)
        } else {
          filteredList[key] = [localCartItem]
        }
      })
      setSortedItems({ ...filteredList })

      if (appliedCouponInCart.length) {
        setAppliedCoupon(appliedCouponInCart)
      }
    }
  }

  useEffect(() => {
    setCartItems()
  }, [cart])

  useEffect(() => {
    setCartItems()
  }, [])

  const onUpdateCartItem = (
    item: TicketType | ProductType | PrintifyProductType,
    event: EventType | undefined,
    quantity: number
  ) => {
    if (quantity === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void deleteCartItem(item)
    } else {
      void updateItem({
        item,
        itemType:
          applyVat || location.state?.applyVat
            ? CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT
            : CartItemTypeEnum.CART_ITEM_TYPE_TICKET,
        event,
        quantity,
      })
    }
  }

  // useEffect(() => {
  //   if (subTotalPrice !== 0 && appliedCoupon !== undefined) {
  //     void applyCoupon(appliedCoupon)
  //   }
  // }, [subTotalPrice])

  useEffect(() => {
    let total = donationAmount || 0

    if (shippingCost && shippingCost.standard) {
      total += shippingCost.standard * 1.2
    }

    if (applyVat || location.state?.applyVat) {
      total += subTotalPrice * 1.2
    } else if (afterCouponTotal !== undefined) {
      total += afterCouponTotal
    } else {
      total += subTotalPrice
    }

    setTotalPrice(Math.round(total * 100) / 100)
  }, [
    applyVat,
    subTotalPrice,
    donationAmount,
    shippingCost,
    location,
    afterCouponTotal,
  ])

  useEffect(() => {
    if (cart && isPartialPayment) {
      let subtotal = 0
      cart.items.forEach((cartItem) => {
        if (paymentOption === PaymentOptions[0].value) {
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        } else if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
          (cartItem.item as ProductType).product_type ===
            CartItemProductTypeEnum.PRODUCT_TYPE_STALL
        ) {
          const discountPercent =
            (cartItem.item as ProductType).category.id === 4 ? 0.4 : 0.6
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) *
            cartItem.quantity *
            discountPercent
        } else {
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        }
      })

      setSubTotalPrice(subtotal)
    }
  }, [cart, paymentOption, isPartialPayment])

  const getPartialPayment = (cartItem: NewCartType): number => {
    if (
      cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
      (cartItem.item as ProductType).product_type ===
        CartItemProductTypeEnum.PRODUCT_TYPE_STALL
    ) {
      const discountPercent =
        (cartItem.item as ProductType).category.id === 4 ? 0.4 : 0.6

      return (
        Number.parseFloat(`${cartItem.price_per_unit}`) *
        cartItem.quantity *
        discountPercent
      )
    }
    return Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
  }

  const getBookingFee = useMemo(() => {
    if (
      cart &&
      cart.items.some(
        (cartItem) => cartItem.item_type === CART_ITEM_TYPE_TICKET
      ) &&
      totalPrice > 0
    ) {
      // This is done so that when Paypal charges (3% + 0.3) on the total,
      // we recevie totalPrice in our account
      return Math.round((totalPrice / 0.97 + 0.3 - totalPrice) * 100) / 100
    }

    return 0
  }, [cart, totalPrice])

  const handleNextClick = () => {
    navigate('/checkout/payment', {
      state: {
        donationAmount,
        isPartialPayment,
        applyVat: !!applyVat || !!location.state?.applyVat,
        bookingFee: getBookingFee,
      },
    })
  }

  const handleRemoveCoupon = useCallback(async () => {
    try {
      const { success } = await removeCoupon()
      if (success) {
        void getCart()
      }
    } catch (error) {
      console.log(error)
    }
  }, [getCart, removeCoupon])

  return (
    <>
      <PageTitle title="Checkout" />
      <Container className="mt-4 mt-xl-5 pt-4 pt-xl-5 mb-3 checkout">
        {isPartialPayment ? (
          <Row className="m-0 my-4">
            <Col md={1} />
            <Col md={7}>
              <p className="fw-bold mb-2">Select Payment Option</p>
              <Form>
                {PaymentOptions.map((option) => (
                  <Form.Check
                    key={option.name}
                    inline
                    type="radio"
                    id={option.name}
                    label={option.name}
                    value={option.value}
                    checked={paymentOption === option.value}
                    onChange={() => setPaymentOption(option.value)}
                  />
                ))}
              </Form>
              {paymentOption === PaymentOptions[1].value ? (
                <p className="text-danger my-2 fw-bold">
                  Please Note: Partial Payment applies only on stall item
                  purchases i.e. 60% pay now for domestic exhibitors and 40% pay
                  now for International Exhibitors.
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col md={1} />
          <Col md={7}>
            <Card className="border-2 border-primary rounded-3 p-3">
              <Row className="border-bottom border-dark pb-2 mx-0">
                <Col
                  xs={4}
                  sm={4}
                  md={5}
                  className={`p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Ticket
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  className={`text-center p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Price
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Quantity
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Total
                </Col>
              </Row>
              {Object.entries(sortedItems).map(([key, cartItems]) => (
                <Fragment key={key}>
                  <Row className="mx-0 mt-2">
                    <Col md={12} className={`${isMobile ? 'p-0' : ''}`}>
                      <p className={`fw-bold ${isMobile ? 'fs-6' : 'fs-5'}`}>
                        {key}
                      </p>
                    </Col>
                  </Row>
                  <Row className="border-dark border-bottom border-opacity-25 mb-3 pb-2 mx-0">
                    {cartItems.map((cartItem) => (
                      <Fragment key={cartItem.id}>
                        <Col
                          xs={4}
                          sm={4}
                          md={5}
                          className={`fs-8 d-flex align-items-center pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          {cartItem.item.name}
                          {cartItem.object_id
                            ? ` (${cartItem.object_id.join(', ')})`
                            : ''}
                        </Col>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="fw-bolder d-flex align-items-center justify-content-center pb-2"
                        >
                          £{cartItem.displayPrice}
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={2}
                          className="d-flex align-items-center justify-content-center pb-2"
                        >
                          {isMobile ? (
                            <p className="fs-7">{cartItem.displayQuantity}</p>
                          ) : (
                            <p className="fs-7">{cartItem.displayQuantity}</p>
                            // <QuantityPicker
                            //   min={0}
                            //   max={99}
                            //   initialValue={cartItem.displayQuantity}
                            //   onChange={(quantity) =>
                            //     onUpdateCartItem(
                            //       cartItem.item,
                            //       (cartItem.item as TicketType).event ||
                            //         undefined,
                            //       quantity
                            //     )
                            //   }
                            //   wrapperClass="px-md-3"
                            // />
                          )}
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={3}
                          className={`d-flex align-items-center justify-content-end pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          {cartItem.discount_coupan !== null ? (
                            <span className="text-decoration-line-through pe-3">
                              £{cartItem.price_per_unit * cartItem.quantity}
                            </span>
                          ) : (
                            <></>
                          )}
                          {paymentOption === PaymentOptions[1].value &&
                          cartItem.item_type ===
                            CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
                          (cartItem.item as ProductType).product_type ===
                            CartItemProductTypeEnum.PRODUCT_TYPE_STALL ? (
                            <span className="text-decoration-line-through pe-3">
                              £{cartItem.price_per_unit * cartItem.quantity}
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fw-bolder">
                            £
                            {paymentOption === PaymentOptions[1].value &&
                            cartItem.item_type ===
                              CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
                            (cartItem.item as ProductType).product_type ===
                              CartItemProductTypeEnum.PRODUCT_TYPE_STALL
                              ? getPartialPayment(cartItem)
                              : cartItem.total}
                          </span>
                        </Col>
                      </Fragment>
                    ))}
                  </Row>
                </Fragment>
              ))}
            </Card>
          </Col>
          <Col md={3} className="mt-4 mt-md-0">
            <div className="mb-3 d-flex flex-column d-md-block">
              <p className="fw-bold">Promotional Code</p>
              <input
                className="bg-white rounded-pill border-1 border-dark border-opacity-25 px-3 py-2 fs-7 text-black"
                value={discountCode}
                onChange={(event) => setDiscountCode(event.target.value)}
                placeholder="Promotional Code"
              />
              <Button
                variant="primary"
                className="rounded-pill mt-3"
                onClick={() => {
                  void applyCoupon(discountCode)
                  setDiscountCode('')
                }}
              >
                Apply Code
              </Button>
            </div>
          </Col>
          <Col md={1} />
        </Row>
        <Row>
          <Col md={1} />
          <Col md={7}>
            <Card className="border-2 border-primary rounded-3 p-2 mt-5">
              {!(applyVat || location.state?.applyVat) ? (
                <Row className="m-0 mb-2">
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    className="fw-bold d-flex align-items-center"
                  >
                    Make Donation
                  </Col>
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    className="text-end d-flex flex-row align-items-center"
                  >
                    £
                    <input
                      type="number"
                      className="donation text-center bg-white rounded-pill border-1 border-dark px-2 py-1 fs-7 text-black ms-2"
                      value={donationAmount}
                      onChange={(event) => {
                        const value = Number.parseFloat(
                          event.target.value || '0'
                        )
                        if (value > -1) {
                          setDonationAmount(value)
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.which === 38 || e.which === 40) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mx-0 mt-4 pb-2">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Subtotal
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {subTotalPrice}
                </Col>
              </Row>
              {!!shippingCost && shippingCost.standard && (
                <Row className="mx-0 pb-2">
                  <Col md={6} className="fw-bold">
                    Shipping Cost
                  </Col>
                  <Col md={6} className="text-end fw-semibold">
                    £ {shippingCost.standard}
                  </Col>
                </Row>
              )}
              {applyVat || location.state?.applyVat ? (
                <Row className="mx-0 pb-2">
                  <Col xs={8} sm={8} md={8} className="fw-bold">
                    VAT 20%
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £{' '}
                    {Math.round(
                      (subTotalPrice + (shippingCost?.standard || 0)) *
                        0.2 *
                        100
                    ) / 100}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {appliedCoupon ? (
                <Row className="mx-0 pb-2 d-flex align-items-center">
                  <Col xs={6} sm={6} md={6}>
                    <span className="fw-bold">Applied Coupon code</span>
                    {/* <div className="d-flex align-items-center">
                      <Button
                        variant="outline-danger"
                        className="fw-semibold px-2 py-1 ms-2"
                        onClick={() => applyCoupon('')}
                      >
                        {appliedCoupon} X
                      </Button>
                    </div> */}
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    className="text-end fw-semibold text-danger"
                  >
                    <Button
                      variant="outline-danger"
                      className="fw-semibold px-2 py-1 ms-2"
                      onClick={() => void handleRemoveCoupon()}
                    >
                      {appliedCoupon} X
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {getBookingFee > 0 && (
                <Row className="mx-0 mt-0 pt-3">
                  <Col xs={8} sm={8} md={8} className="fw-bold">
                    Booking Fee
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £ {getBookingFee}
                  </Col>
                </Row>
              )}
              <Row className="mx-0 mt-4 mb-2 pt-3 border-top border-dark border-opacity-25">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Total
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {totalPrice + getBookingFee}
                </Col>
              </Row>
              <Row className="mx-0 mt-4 mb-2">
                <Col className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    className="rounded-pill mt-3 w-100"
                    onClick={handleNextClick}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={3} />
          <Col md={1} />
        </Row>
      </Container>
    </>
  )
}
