import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useModal } from '@ebay/nice-modal-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import {
  PageTitle,
  ProductImageGallery,
  QuantityPicker,
} from '../../../components'
import {
  PrintifyProductType,
  PrintifyProductVariantType,
} from '../../../types/printifyProduct.types'
import { useFetch } from '../../../utils/hooks'
import './productDetails.scss'
import { ExtraProductInfo } from './productExtraInfo'
import { useAuthentication, useCart } from '../../../utils/contexts'
import { CartItemTypeEnum } from '../../../utils/contexts/CartContext/cartContext.type'
import { ProductVariants } from '../../../components/Printify/ProductVariants'
import { AuthModal } from '../../../components/AuthModal'

export const PrintifyProductDetails = () => {
  const { productId } = useParams()
  const { cart, addToCart } = useCart()
  const modal = useModal(AuthModal)
  const { token } = useAuthentication()

  const [quantity, setQuantity] = useState(1)
  const [selectedVariant, setSelectedVariant] =
    useState<PrintifyProductVariantType>()

  const { data: product, error } = useFetch<{
    data: PrintifyProductType
  }>(`/products/${productId}?product_type=printify`)

  useEffect(() => {
    if (error) {
      toast.error('Error fetching list of products. Please try again later')
    }
  }, [error])

  const handleAddToCart = async () => {
    if (token.length === 0 || cart === undefined) {
      await modal.show()
    } else if (selectedVariant && product) {
      void addToCart({
        item: {
          ...product.data,
          name: `${product.data.name} ${selectedVariant.title}`,
          price: selectedVariant.price,
        },
        itemType: CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT,
        quantity,
        variantId: selectedVariant.id,
      })
    }
  }

  return (
    <>
      <PageTitle title="Product Detail" />
      <Container className="pt-md-5">
        {product ? (
          <>
            <Row className="p-3 border-primary rounded-3 top-wrapper">
              <Col md={0} lg={1} />
              <Col md={6} lg={6}>
                <ProductImageGallery images={product?.data.images || []} />
              </Col>
              <Col md={6} lg={5}>
                <div className="d-flex flex-column justify-content-between h-100 mt-md-0 mt-4">
                  <h3 className="fw-bold fs-md-6 fs-5">{product?.data.name}</h3>
                  <div>
                    <div className="mb-3 quantity-wrapper">
                      <p className="fs-7 mb-2">Quantity</p>
                      <QuantityPicker
                        min={1}
                        max={10}
                        onChange={(quantity) => setQuantity(quantity)}
                        wrapperClass="px-3 py-1 py-md-2"
                      />
                    </div>
                    <ProductVariants
                      options={product.data.attributes.options}
                      variants={product.data.variants}
                      onChange={setSelectedVariant}
                    />
                  </div>
                  <div>
                    <p>
                      <span className="fw-bold fs-5">
                        £{' '}
                        {selectedVariant?.price ||
                          (product && product.data.price) ||
                          0}
                      </span>{' '}
                      excl. shipping & VAT
                    </p>
                    <Button
                      variant="primary"
                      className="rounded-pill px-3 py-2"
                      onClick={() => void handleAddToCart()}
                    >
                      ADD TO CART
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="my-3 py-3 border-bottom border-secondary">
              <Col md={3} lg={4}>
                <p className="text-secondary fw-bold fs-5">About Product</p>
              </Col>
              <Col md={9} lg={8}>
                <p
                  className="text-secondary description"
                  dangerouslySetInnerHTML={{
                    __html: product?.data.description || '',
                  }}
                />
              </Col>
            </Row>
            <Row className="my-3 py-3 border-bottom border-danger">
              <Col md={3} lg={4}>
                <p className="text-danger fw-bold fs-5">Key Features</p>
              </Col>
              <Col md={9} lg={8}>
                <div className="d-flex flex-row flex-wrap">
                  {ExtraProductInfo[
                    `${product.data.attributes.printify_id}`
                  ].features.map((feature) => (
                    <div className="w-md-100 w-50 mb-4" key={feature.title}>
                      <feature.icon />
                      <p className="text-danger fs-5 fw-bold mt-2">
                        {feature.title}
                      </p>
                      <p className="text-danger fs-7 w-85">
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="my-3 py-3">
              <Col md={3} lg={4}>
                <p className="text-warning fw-bold fs-5">Care Instructions</p>
              </Col>
              <Col md={9} lg={8}>
                <div className="d-flex flex-column">
                  {ExtraProductInfo[`${product.data.attributes.printify_id}`]
                    .care.icons.length > 0 ? (
                    <div className="d-flex flex-row mb-3">
                      {ExtraProductInfo[
                        `${product.data.attributes.printify_id}`
                      ].care.icons.map((CareIcon, index) => (
                        <div className="me-2" key={`${Math.floor(index)}`}>
                          <CareIcon />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  <p className="text-warning">
                    {
                      ExtraProductInfo[`${product.data.attributes.printify_id}`]
                        .care.text
                    }
                  </p>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}
