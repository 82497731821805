import { Outlet } from 'react-router-dom'

import { PageTitle } from '../../components'

export const TicketsRoot = () => {
  return (
    <>
      <PageTitle title="Ticket Booking" />
      <Outlet />
    </>
  )
}
