import { useState, useEffect, useCallback } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

import { ProductVariantsProps, SelectedOption } from './productVariants.types'
import { PrintifyProductOptionType } from '../../../types/printifyProduct.types'

const arrayEquals = (a: number[], b: number[]) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

export const ProductVariants = ({
  options,
  variants,
  onChange,
}: ProductVariantsProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectedOption>({})
  const [availableOptions, setAvailableOptions] = useState<
    Array<PrintifyProductOptionType>
  >([])

  const onProductVariantChange = useCallback(
    (option: SelectedOption) => {
      const selectedOptionIds: number[] = Object.values(option).map(
        (selectedOptionValue) => selectedOptionValue.id
      )

      if (selectedOptionIds.length) {
        const selectedVariant = variants.find((variant) => {
          return arrayEquals(variant.options, selectedOptionIds)
        })

        if (selectedVariant) {
          onChange(selectedVariant)
        }
      }
    },
    [variants]
  )

  useEffect(() => {
    const availableOptionIds = new Set()
    variants.forEach((variant) => {
      if (variant.price && variant.price > 0) {
        variant.options.forEach((variantOption) => {
          availableOptionIds.add(variantOption)
        })
      }
    })

    const availableOptions = options.map((option) => {
      const optionValue = option.values[0]

      if (optionValue) {
        setSelectedOption((value) => ({
          ...value,
          [option.type]: {
            id: optionValue.id,
            value: optionValue.title,
          },
        }))
      }

      const newOptionValues = option.values.filter((optionValue) =>
        availableOptionIds.has(optionValue.id)
      )

      return {
        ...option,
        values: [...newOptionValues],
      }
    })

    setAvailableOptions(availableOptions)
  }, [])

  useEffect(() => {
    onProductVariantChange(selectedOption)
  }, [selectedOption])

  const handleOptionChange = useCallback(
    (optionType: string, optionId: number, optionValueTitle: string) => {
      setSelectedOption((value) => ({
        ...value,
        [optionType]: {
          id: optionId,
          value: optionValueTitle,
        },
      }))
    },
    []
  )

  return (
    <>
      {availableOptions.map((option) => (
        <div className="mb-2 mb-md-3" key={option.type}>
          <p className="fs-7 mb-2">{option.name}</p>
          <Dropdown align="start">
            <Dropdown.Toggle
              variant="outline-dark"
              className="rounded-pill text-start position-relative"
            >
              {selectedOption[option.type]?.value}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {option.values.map((optionValue) => (
                <Dropdown.Item
                  key={optionValue.id}
                  onClick={() =>
                    handleOptionChange(
                      `${option.type}`,
                      optionValue.id,
                      `${optionValue.title}`
                    )
                  }
                >
                  {optionValue.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ))}
    </>
  )
}
