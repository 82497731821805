import { useNavigate } from 'react-router-dom'

import { PrintifyProductListType } from '../../../types/printifyProduct.types'
import './productCard.scss'

type PrintifyProductCardProps = {
  product: PrintifyProductListType
}

export const PrintifyProductCard = ({ product }: PrintifyProductCardProps) => {
  const navigate = useNavigate()

  return (
    <div
      className="product-card d-flex flex-column border border-2 border-primary rounded-3 overflow-hidden cursor-pointer position-relative text-decoration-none my-2"
      onClick={() => {
        navigate(`/products/${product.id}`, { state: { product } })
      }}
      onKeyUp={() => {
        navigate(`/products/${product.id}`, { state: { product } })
      }}
      role="button"
      tabIndex={0}
    >
      <div className="mx-auto w-100 p-2">
        <img
          className="w-100"
          src={product?.images[0]?.url}
          alt={product?.images[0]?.name}
        />
      </div>
      <div className="d-flex justify-content-between bg-primary p-2 flex-column">
        <p className="fs-5 fs-md-6 text-white m-0 name">{product.name}</p>
        <p className="fs-5 fs-md-6 text-white m-0">{`£ ${
          product.price || 0
        }`}</p>
      </div>
    </div>
  )
}
