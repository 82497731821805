import { ReactNode, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useModal } from '@ebay/nice-modal-react'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { toast } from 'react-toastify'

import { QuantityPicker } from '../QuantityPicker'
import { TicketRowProps } from './ticketRow.types'
import { useCart, useAuthentication, useSeats } from '../../utils/contexts'
import { AuthModal } from '../AuthModal'
import { getQuantityPrice, sleep } from '../../utils/helpers'
import {
  CartItemType,
  CartItemTypeEnum,
} from '../../utils/contexts/CartContext/cartContext.type'
import {
  ACCOMPANYING_ADULT_TICKET_IDS,
  KIDS_BELOW_TWO_YRS_TICKET_IDS,
  KIDS_EVENT_IDS,
  KIDS_TICKET_IDS,
  NORMAL_EVENT_IDS,
} from '../../utils/constants'
import { TicketStatusEnum, TicketType } from '../../types'
import { SeatingChartModal } from '../SeatsModal/seatsModal'
import { SelectedSeats } from '../SeatsModal/seatsModal.types'

const CustomToggle = ({
  children,
  eventKey,
}: {
  children: ReactNode
  eventKey: string
}) => {
  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <Button variant="ghost" onClick={decoratedOnClick} className="border-0">
      {children}
    </Button>
  )
}

export const TicketRow: React.FC<TicketRowProps> = ({
  ticket,
  event,
  tickets,
}) => {
  const { startHoldCountDown } = useSeats()
  const authModal = useModal(AuthModal)
  const seatingChartModal = useModal(SeatingChartModal)
  const { token } = useAuthentication()
  const { cart, addToCart, updateItem, deleteCartItem } = useCart()
  const [initialQuantity, setInitialQuantity] = useState(0)
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(99)
  const [disableAddToCart, setDisableAddToCart] = useState(false)

  const onUpdateCartItem = (
    quantity: number,
    seatTicket?: TicketType,
    seatIds?: string[]
  ) => {
    if (quantity === 0) {
      void deleteCartItem(ticket)
      setInitialQuantity(0)
    } else {
      void updateItem({
        item: seatTicket || ticket,
        itemType: CartItemTypeEnum.CART_ITEM_TYPE_TICKET,
        event,
        quantity: seatIds?.length || 1,
        seatIds,
      })
    }
  }

  const onAddToCart = async (seatTicket?: TicketType, seatIds?: string[]) => {
    if (token.length === 0 || cart === undefined) {
      await authModal.show()
    } else {
      await addToCart({
        item: seatTicket || ticket,
        itemType: CartItemTypeEnum.CART_ITEM_TYPE_TICKET,
        event,
        quantity: seatIds?.length || 1,
        seatIds,
      })
    }
  }

  const handleAddSeatsToCart = (seatsObj: SelectedSeats) => {
    for (const [key, value] of Object.entries(seatsObj)) {
      const ticket: TicketType | undefined = tickets.find(
        (ticket) => ticket.name === key
      )
      if (ticket) {
        void onAddToCart(ticket, value)
      }
    }
  }

  const handleUpdateSeatsToCart = (seatsObj: SelectedSeats) => {
    for (const [key, value] of Object.entries(seatsObj)) {
      const ticket: TicketType | undefined = tickets.find(
        (ticket) => ticket.name === key
      )
      if (ticket) {
        void onUpdateCartItem(value.length, ticket, value)
      }
    }
  }

  useEffect(() => {
    if (cart) {
      const cartItem = cart.items.find(
        (cartItem) =>
          cartItem.item.id === ticket.id &&
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
      )

      const adultTicketForCurrentEvent = cart.items.find((cartItem) =>
        ACCOMPANYING_ADULT_TICKET_IDS[event.id]?.includes(cartItem.item.id)
      )

      // If current event is Kids Garba, then link Children ticket to Accompanying adult ticket
      if (KIDS_EVENT_IDS?.includes(event.id)) {
        // Kids Ticket -> ABOVE or BELOW 2 years
        const kidTicketForCurrentEvent = cart.items.filter((cartItem) =>
          KIDS_TICKET_IDS[event.id]?.includes(cartItem.item.id)
        )
        let quantityOfKidsTickets = 0
        if (kidTicketForCurrentEvent.length > 0) {
          kidTicketForCurrentEvent.forEach((kidTicket) => {
            quantityOfKidsTickets += kidTicket.quantity
          })
        }

        // Adding Accompanying Adult ticket if only kid ticket in the cart
        if (
          kidTicketForCurrentEvent.length > 0 &&
          !adultTicketForCurrentEvent
        ) {
          if (ACCOMPANYING_ADULT_TICKET_IDS[event.id]?.includes(ticket.id)) {
            setMinQuantity(1)
            void onAddToCart()
          }
        }

        // Update max quantity of accompanying adult ticket
        if (kidTicketForCurrentEvent.length > 0) {
          if (ACCOMPANYING_ADULT_TICKET_IDS[event.id]?.includes(ticket.id)) {
            setMaxQuantity(quantityOfKidsTickets)
            setDisableAddToCart(false)
          }
        } else if (
          ACCOMPANYING_ADULT_TICKET_IDS[event.id]?.includes(ticket.id)
        ) {
          // If no kid ticketin cart, remove accompanying adult ticket as well
          setMaxQuantity(0)
          setMinQuantity(0)
          setDisableAddToCart(true)
          onUpdateCartItem(0)
        }

        // If number of Kid ticket is less than number of accompanying adult, update quantity of accompanying adult
        if (kidTicketForCurrentEvent.length > 0 && adultTicketForCurrentEvent) {
          if (
            adultTicketForCurrentEvent.item.id === ticket.id &&
            adultTicketForCurrentEvent.quantity > quantityOfKidsTickets
          ) {
            onUpdateCartItem(quantityOfKidsTickets)
          }
        }
      }

      // Allow child ticket (below 2 years) to be added to cart only if Adult ticket is added to the cart
      if (NORMAL_EVENT_IDS?.includes(event.id)) {
        const kidTicketForCurrentEvent = cart.items.find((cartItem) =>
          KIDS_BELOW_TWO_YRS_TICKET_IDS[event.id]?.includes(cartItem.item.id)
        )

        if (KIDS_BELOW_TWO_YRS_TICKET_IDS[event.id]?.includes(ticket.id)) {
          if (!kidTicketForCurrentEvent && !adultTicketForCurrentEvent) {
            setDisableAddToCart(true)
          } else if (!kidTicketForCurrentEvent && adultTicketForCurrentEvent) {
            setDisableAddToCart(false)
          } else if (
            kidTicketForCurrentEvent &&
            !adultTicketForCurrentEvent &&
            kidTicketForCurrentEvent.item.id === ticket.id
          ) {
            // If Adult ticket is removed from current event, remove child ticket as well
            onUpdateCartItem(0)
          } else {
            setDisableAddToCart(true)
          }
        } else {
          setDisableAddToCart(false)
        }
      }

      if (cartItem) {
        const { newQuantity } = getQuantityPrice(cartItem)
        setInitialQuantity(newQuantity)
      } else {
        setInitialQuantity(0)
      }
    } else {
      // if (KIDS_EVENT_IDS.includes(event.id)) {
      //   if (ACCOMPANYING_ADULT_TICKET_IDS[event.id].includes(ticket.id)) {
      //     setDisableAddToCart(true)
      //   }
      // }
      // if (NORMAL_EVENT_IDS.includes(event.id)) {
      //   if (ACCOMPANYING_ADULT_TICKET_IDS[event.id].includes(ticket.id)) {
      //     setDisableAddToCart(false)
      //   } else {
      //     setDisableAddToCart(true)
      //   }
      // }
    }
  }, [cart])

  const renderAddToCart = () => {
    const getButtonText = () => {
      if (ticket.status === TicketStatusEnum.DISPLAY_AS_SOLD_OUT)
        return 'Sold Out'
      if (initialQuantity > 0) return 'Update Seats'
      return 'Select Seats'
    }

    if (event.seating_chart !== null) {
      return (
        <Button
          variant="primary"
          className="rounded rounded-pill fs-md-7"
          onClick={() => {
            if (
              ticket.status !== TicketStatusEnum.DISPLAY_AS_SOLD_OUT &&
              !disableAddToCart
            ) {
              // startHoldCountDown()
              void seatingChartModal.show({
                eventId: event.id,
                pricing: tickets.map((ticket) => ({
                  category: ticket.name,
                  ticketTypes: [
                    { ticketType: ticket.name, price: ticket.price },
                  ],
                })),
                onSaveSelectedSeats: (seatsObj) => {
                  if (initialQuantity > 0) {
                    handleUpdateSeatsToCart(seatsObj)
                  } else {
                    handleAddSeatsToCart(seatsObj)
                  }
                  void seatingChartModal.hide()
                },
              })
              toast.warn('The seats will be reserved for 15 minutes.', {
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              })
            }
          }}
          disabled={
            ticket.status === TicketStatusEnum.DISPLAY_AS_SOLD_OUT ||
            disableAddToCart
          }
        >
          {getButtonText()}
        </Button>
      )
    }

    if (initialQuantity > 0) {
      return (
        <QuantityPicker
          min={minQuantity}
          max={maxQuantity}
          initialValue={initialQuantity}
          onChange={(quantity) => onUpdateCartItem(quantity)}
          wrapperClass="px-3 py-1 py-md-2"
        />
      )
    }

    return (
      <Button
        variant="primary"
        className="rounded rounded-pill fs-md-7"
        onClick={() => {
          if (
            ticket.status !== TicketStatusEnum.DISPLAY_AS_SOLD_OUT &&
            !disableAddToCart
          ) {
            void onAddToCart()
          }
        }}
        disabled={
          ticket.status === TicketStatusEnum.DISPLAY_AS_SOLD_OUT ||
          disableAddToCart
        }
      >
        {ticket.status === TicketStatusEnum.DISPLAY_AS_SOLD_OUT
          ? 'Sold Out'
          : 'Add to Cart'}
      </Button>
    )
  }

  return (
    <div className="p-3">
      <Row className="border-bottom border-primary mx-0">
        <Col xs={8} sm={6} md={9}>
          <span className="fs-4 fs-md-6 fw-bold">{ticket.name}</span>
        </Col>
        <Col xs={4} sm={6} md={3} className="d-flex justify-content-end">
          <span className="fs-4 fs-md-6 fw-bold">£ {ticket.price}</span>
        </Col>
      </Row>
      <Row className="mt-1 mt-md-3">
        <Col md={9}>
          <Accordion>
            <Accordion.Item eventKey="0" className="border-0">
              <CustomToggle eventKey="0">
                <span className="fs-7 fs-md-8">Show Details</span>
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <div
                  className="fs-7 fs-md-8"
                  dangerouslySetInnerHTML={{ __html: ticket.description }}
                />
              </Accordion.Collapse>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col
          md={3}
          className="d-flex align-items-md-end justify-content-md-end mt-1 mt-md-0"
        >
          {renderAddToCart()}
        </Col>
      </Row>
    </div>
  )
}
